<template>
  <v-row style="direction: ltr;">
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/calendar.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4 style="padding-top:30px;">{{$t("Activities Schedule")}}</h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'direction': 'rtl'} : ''">
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-card flat>
              <v-card-text>
                <v-card flat dark>
                  <v-card-text>
                    <v-icon color="blue">mdi-checkbox-blank</v-icon> {{$t("Lessons")}}
                    <br />
                    <v-icon color="green">mdi-checkbox-blank</v-icon>
                    {{$t("Assignments")}}
                    <br />
                    <v-icon color="red">mdi-checkbox-blank</v-icon>{{$t("Live Sessions")}}
                  </v-card-text>
                </v-card>
                <v-sheet>
                  <v-list shaped>
                    <v-list-item
                      v-for="subject in subjects"
                      :key="subject.key"
                      href="#"
                      @click="getSubject(subject.path, subject.mirror)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-subtitles-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        {{ subject.title }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list></v-sheet
                ></v-card-text
              ></v-card
            >
          </v-col>
          <v-col cols="8">
            <v-card>
              <v-card-text>
                <v-sheet height="64">
                  <v-toolbar flat>
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-spacer />

                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>{{$t("Week")}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <!-- <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="success"
                    :events="events"
                    :short-weekdays="false"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                  ></v-calendar> -->
                  <v-calendar
                  :attributes="events"
                  @dayclick="click"
                  >
                  <!-- <template #day-popover>
                                  <div
                  slot="day-popover"
                  slot-scope="{ dayTitle, attributes }">
                  <div>
                    {{ dayTitle }}
                  </div>
                  <ul>
                    <li
                      v-for="{key, customData} in attributes"
                      :key="key">
                      {{ customData.link }}
                    </li>
                  </ul>
                </div>
                </template> -->
                  <template #day-popover="{ day }">
    <div style="display: flex;  align-items: center;flex-direction: column;">
      <div >{{ day.ariaLabel }}</div>
      <div >{{ day.attributes[0].customData.name }}</div>
      <a :href="day.attributes[0].customData.link"  target="_blank">{{ day.attributes[0].customData.link }}</a>
    </div>
  </template></v-calendar>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container> </v-col
  ></v-row>
</template>
<script>
import firebase from "@/Firebase";

export default {
  name: "Calendar-Page",
  data() {
    return {
      grade: this.$store.state.grade,
      loading: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      focus: "",
      subjects: [],
      events: [],
      attributes: [
        {
          highlight: "blue",
          dates:  new Date(2024, 8, 29),
        }
      ],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ]
    };
  },
  created() {
    firebase
      .firestore()
      .collection(this.$store.state.educationPath + "/subjects/")
      .get()
      .then(querySnapshot => {
        this.subjects = [];
        querySnapshot.forEach(subject => {
          this.subjects.push({
            key: subject.id,
            path: subject.ref.path,
            title: subject.data().title,
            mirror: subject.data().mirror
          });
        });
      });
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },

    showEvent({ nativeEvent, event }) {
      console.log(nativeEvent, event);
    },
    click(day) {
      console.log("clicked",day);
    },
    getSubject(path, mirror) {
      this.loading = false;
      this.events = [];
      var subject = "";
      if (mirror != null || mirror != "") {
        subject = mirror;
      } else {
        subject = path;
      }
      console.log(subject)
      console.log(path)
      //! Get Lessons
      firebase
        .firestore()
        .collection(path + "/weeks/")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().date != null && doc.data().date != "")
              // this.events.push({
              //   name: doc.data().title,
              //   start: doc.data().date,
              //   end: doc.data().date,
              //   color: "blue",
              //   timed: false
              // });
              firebase
        .firestore()
        .collection(doc.ref.path + "/lesson/")
        .where("type","==","Live Session")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            if (doc.data().livedate != null){
              console.log(doc.data())
              this.events.push({
                dot: 'red',
                dates:  new Date(doc.data().livedate),
                popover: true,
                customData:{
                name: doc.data().title,
                time: doc.data().livetime,
                link:doc.data().livelink
                }
              });
            }
          });
        });
          });
        });

            //! Get Lesssons assignments
            // firebase
            //   .firestore()
            //   .collection(path + "/tasks/")
            //   .get()
            //   .then(querySnapshot => {
            //     querySnapshot.forEach(doc => {
            //       if (
            //         doc.data().opendate != null &&
            //         doc.data().closedate != null
            //       )
            //         this.events.push({
            //           name: doc.data().title,
            //           start: doc.data().opendate,
            //           end: doc.data().closedate,
            //           color: "green",
            //           timed: false
            //         });
            //     });
            //   })
            //   .then(() => {
            //     this.loading = true;
            //   });
      //! Get Lesssons Live Sessions
      // firebase
      //   .firestore()
      //   .collection(path + "/sessions/")
      //   .get()
      //   .then(querySnapshot => {
      //     querySnapshot.forEach(doc => {
      //       console.log(doc.data())
      //       if (doc.data().date != null)
      //         this.events.push({
      //           name: doc.data().title,
      //           start: doc.data().date,
      //           end: doc.data().date,
      //           color: "red",
      //           timed: false
      //         });
      //     });
      //   });
    }
  }
};
</script>
