<template>
  <v-row>
    <!-- Side Exam Panel -->

    <v-col cols="3">
      <v-navigation-drawer
        fixed
        dark
        src="@/assets/videoheader.jpg"
        style="z-index:2; padding-left:50px;"
        width="28%"
        permanent
      >
        <!-- Activity Title -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h4
                  style="padding-top:30px;"
                  v-if="this.$store.state.videotype == 'videos'"
                >
                {{ $t("Lesson Videos") }} 
                </h4>
                <h4 style="padding-top:30px;" v-else>
                  {{$t("Related videos")}}
                </h4>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.subject + " " + this.grade }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-col>
    <!-- Side Exam Panel -->
    <v-col cols="9" :style="$i18n.locale=='Arabic'? {'margin-left': '28%'} : ''">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto" tile flat outlined>
              <v-card-text>
                <div v-if="this.type == 'videos'">
                  <v-card
                    v-for="n in videos"
                    :key="n.key"
                    flat
                    shaped
                    outlined
                    style="margin-bottom:10px"
                  >
                    <v-card-subtitle>
                      <v-icon>mdi-youtube</v-icon>{{ n.title }}</v-card-subtitle
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <iframe
                            :src="n.link"
                            width="100%"
                            height="300px"
                            marginwidth="0"
                            marginheight="0"
                            hspace="0"
                            vspace="0"
                            frameborder="0"
                            scrolling="no"
                            allowfullscreen
                            v-if="n.link.includes('vimeo')"
                          />
                          <video-embed
                            :src="n.link"
                            style="width:100%"
                            v-else
                          ></video-embed>
                        </v-col>
                        <v-col cols="6">
                          <div v-html="n.description" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-divider />
                      <!--
                    <v-btn color="orange" depressed small>
                      <v-icon>mdi-help-circle-outline</v-icon>
                      Leave Question to The Teacher</v-btn
                    >
                    <v-btn color="success" depressed small>
                      <v-icon>mdi-thought-bubble-outline</v-icon>
                      Leave Your Contribution</v-btn
                    >-->
                    </v-card-actions>
                  </v-card>
                </div>
                <div v-else>
                  <v-card
                    v-for="n in videos"
                    :key="n.key"
                    style="margin-bottom:10px"
                    flat
                    shaped
                    outlined
                  >
                    <v-card-subtitle>
                      <v-icon>mdi-youtube</v-icon>{{ n.title }}</v-card-subtitle
                    >
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <!-- <iframe :src="n.link"></iframe> -->
                          <video-embed
                            :src="n.link"
                            style="width:100%"
                          ></video-embed>
                        </v-col>
                        <v-col cols="6">
                          <div v-html="n.description" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>

              <v-list dense> </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import firebase from "@/Firebase";
export default {
  data() {
    return {
      videos: [],
      path: "",
      title: "",
      subject: this.$store.state.subjecttitle,
      grade: this.$store.state.grade,
      week: this.$store.state.weekpath,
      type: this.$store.state.videotype
    };
  },
  created() {

    if (this.type == "videos") {
      firebase
        .firestore()
        .collection(this.week + "/videos/")
        .get()
        .then(querySnapshot => {
          this.videos = [];
          querySnapshot.forEach(doc => {
            console.log(doc.data().link)
            if(doc.data().link.includes("vimeo")){
              this.videos.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              link: doc.data().link,
              section: doc.data().section,
              description: doc.data().description
            });
            }else{
              this.videos.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              link: doc.data().link+"?rel=0&amp;showinfo=0",
              section: doc.data().section,
              description: doc.data().description
            });
            }
          });
        });
    } else {
      firebase
        .firestore()
        .collection(this.week + "/internetvideos")
        .get()
        .then(querySnapshot => {
          this.videos = [];
          querySnapshot.forEach(doc => {
            if(doc.data().link.includes("vimeo")){
              this.videos.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              link: doc.data().link,
              section: doc.data().section,
              description: doc.data().description
            });
            }else{
              this.videos.push({
              key: doc.id,
              path: doc.ref.path,
              title: doc.data().title,
              link: doc.data().link+"?rel=0&amp;showinfo=0",
              section: doc.data().section,
              description: doc.data().description
            });
            }
          });
        });
    }
  },
  methods: {
    changeVideo(link, title) {
      this.path = link;
      this.title = title;
    }
  }
};
</script>
